// in src/Menu.js
import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import SecurityIcon from '@material-ui/icons/Security';
import MoodIcon from '@material-ui/icons/Mood';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MessageIcon from '@material-ui/icons/Message';

const icons = {
  'roles': <SecurityIcon/>,
  'moods': <MoodIcon/>,
  'groups': <PeopleAltIcon/>,
  'facilities': <ApartmentIcon/>,
  'people': <AccountCircleIcon/>,
  'posts': <MessageIcon/>
}

const forbiddenMenus = [
  'roles',
  'moods',
  'groups'
];

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    return (
        <div>
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        icons[resource.name] ? icons[resource.name] : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
