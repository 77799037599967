import decodeJwt from 'jwt-decode';

export default {
    login: ({ username, password }) => {
          const request = new Request(process.env.REACT_APP_API_ENTRYPOINT + '/authentication_token', {
              method: 'POST',
              body: JSON.stringify({ username, password }),
              headers: new Headers({ 'Content-Type': 'application/json' }),
          });
          return fetch(request)
              .then(response => {
                  if (response.status < 200 || response.status >= 300) {
                      throw new Error(response.statusText);
                  }
                  return response.json();
              })
              .then(({ token }) => {
                  const decodedToken = decodeJwt(token);
                  localStorage.setItem('token', token);
                  localStorage.setItem('permissions', decodedToken.permissions[0]);
                  localStorage.setItem('username', decodedToken.username);
                  setTimeout(() => {  location.reload() }, 100);
              })
              .catch(error => {
              });
      },
      logout: () => {
          localStorage.removeItem('token');
          localStorage.removeItem('permissions');
          return Promise.resolve();
      },
      checkError: error => {
          // ...
      },
      checkAuth: () => {
          return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
      },
      getPermissions: () => {
          const role = localStorage.getItem('permissions');
          return role ? Promise.resolve(role) : Promise.reject();
      }
  };
