import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, EditButton, TopToolbar } from 'react-admin';

const GroupShowTitle = ({ record }) => {
  return <span>Détail d'un Groupe : {record ? `${record.label}` : ''}</span>;
};

const GroupShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
      {
        localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN' && <EditButton basePath={basePath} record={data} />
      }
  </TopToolbar>
);

export default (props) => (
    <Show actions={<GroupShowActions />} {...props} title={<GroupShowTitle />}>
        <SimpleShowLayout>
            <TextField source="label" label="Titre" />
            <ReferenceField label="Propriétaire" source="owner" reference="people">
                <TextField source="username" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);
