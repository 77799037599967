import React from 'react';
import { Show, SimpleShowLayout, TextField, EditButton, TopToolbar } from 'react-admin';

const MoodShowTitle = ({ record }) => {
  return <span>Détail d'un Etablissement : {record ? `${record.label}` : ''}</span>;
};

const MoodShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
  </TopToolbar>
);

export default (props) => (
    <Show {...props} actions={<MoodShowActions />} title={<MoodShowTitle />}>
        <SimpleShowLayout>
            <TextField source="label" label="Titre" />
        </SimpleShowLayout>
    </Show>
);
