import React from 'react';
import { List, Datagrid, TextField, ReferenceField, ShowButton, EditButton } from 'react-admin';

export default (props) => (
    <List
      {...props} sort={{ field: 'label', order: 'ASC' }}
      exporter={false} hasCreate={localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN'}
      title="Liste des Groupes"
      bulkActionButtons={false}
    >
        <Datagrid>
          <TextField source="label" label="Titre"/>
          <ReferenceField label="Propriétaire" source="owner" reference="people" sortable={false}>
              <TextField source="username" />
          </ReferenceField>
          <ShowButton />
          {
            localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN' && <EditButton />
          }
        </Datagrid>
    </List>
);
