import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, SelectInput, required } from 'react-admin';

const GroupEditTitle = ({ record }) => {
  return <span>Modification d'un Groupe : {record ? `${record.label}` : ''}</span>;
};

export default (props) => (
    <Edit {...props} title={<GroupEditTitle />} undoable={false}>
        <SimpleForm>
            <TextInput label="Titre" source="label" />
            <ReferenceInput label="Propriétaire" source="owner" reference="people" validate={[required()]} filterToQuery={searchText => ({ username: searchText })}>
              <AutocompleteInput optionText="username" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
