import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export default (props) => (
    <Create {...props} title="Création d'un Groupe">
        <SimpleForm>
            <TextInput label="Titre" source="label" />
        </SimpleForm>
    </Create>
);
