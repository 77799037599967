import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';

export default (props) => (
    <Create {...props} title="Création d'un Etablissement">
        <SimpleForm>
            <TextInput label="Titre" source="label" />
            {
              localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN' &&
              <ReferenceInput label="Groupe" source="group" reference="groups" validate={[required()]}>
                <SelectInput optionText="label" />
              </ReferenceInput>
            }
        </SimpleForm>
    </Create>
);
