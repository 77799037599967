import React from 'react';
import { List, Datagrid, TextField, ReferenceField, ShowButton, EditButton, DateField, FunctionField } from 'react-admin';

export default (props) => (
    <List {...props} sort={{ field: 'publishedAt', order: 'DESC' }} title="Liste des Publications" exporter={false} hasCreate={false} bulkActionButtons={false}>
        <Datagrid>
          <ReferenceField label="Auteur" source="person" reference="people" sortable={false}>
              <TextField source="username" />
          </ReferenceField>
          <ReferenceField label="Humeur" source="mood" reference="moods" sortable={false}>
              <TextField source="label" />
          </ReferenceField>
          <FunctionField label="Signalements" render={record => `${record.reports.length}`} sortBy="reportsCount" />
          <FunctionField label="Appréciations" render={record => `${record.likes.length}`} sortBy="likesCount" />
          <DateField label="Pulié le" source="publishedAt"/>
          <ShowButton />
          <EditButton />
        </Datagrid>
    </List>
);
