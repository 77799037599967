import React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput, ReferenceInput, SelectInput, required } from 'react-admin';

export default (props) => (
    <Create {...props} title="Création d'un Utilisateur">
        <SimpleForm>
            <TextInput label="Nom" source="lastName" />
            <TextInput label="Prénom" source="firstName" />
            <TextInput label="Nom d'utilisateur" source="username" />
            <PasswordInput label="Mot de passe" source="plainPassword" />
            <PasswordInput label="Cofirmation de Mot de passe" source="plainPasswordConfirm" />
            <ReferenceInput label="Rôle" source="role" reference="roles" validate={[required()]}>
                <SelectInput optionText="displayedLabel" />
            </ReferenceInput>
            <ReferenceInput label="Groupe" source="group" reference="groups" validate={[required()]}>
                <SelectInput optionText="label" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
