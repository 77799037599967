import React, { forwardRef } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import { Login, Layout, AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ResourceGuesser from "@api-platform/admin/lib/ResourceGuesser";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import i18nProvider from './lang';
import authProvider from "./provider/authProvider";

import MoodList from './list/MoodList';
import FacilityList from './list/FacilityList';
import GroupList from './list/GroupList';
import PeopleList from "./list/PeopleList";
import PostList from "./list/PostList";

import FacilityShow from "./show/FacilityShow";
import GroupShow from "./show/GroupShow";
import MoodShow from "./show/MoodShow";
import PeopleShow from "./show/PeopleShow";
import PostShow from "./show/PostShow";
import MoodCreate from "./create/MoodCreate";
import GroupCreate from "./create/GroupCreate";
import FacilityCreate from "./create/FacilityCreate";
import PeopleCreate from "./create/PeopleCreate";
import FacilityEdit from "./edit/FacilityEdit";
import GroupEdit from "./edit/GroupEdit";
import MoodEdit from "./edit/MoodEdit";
import PeopleEdit from "./edit/PeopleEdit";
import PostEdit from "./edit/PostEdit";
import RoleList from "./list/RoleList";

import Menu from "./Menu";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import { UserMenu, MenuItemLink } from 'react-admin';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = { Authorization: `Bearer ${localStorage.getItem("token")}` };
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return window.localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const MyLoginPage = () => <Login backgroundImage="" />;

const useStyles = makeStyles({
  title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
  },
  spacer: {
      flex: 1,
  },
});

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
      ref={ref}
      to="/"
      primaryText={localStorage.getItem('username')}
      leftIcon={<AccountBoxIcon />}
      onClick={onClick} // close the menu on click
      disabled={true}
  />
));

const MyUserMenu = props => (
  <UserMenu {...props}>
      <ConfigurationMenu />
  </UserMenu>
);

const MyAppBar = props => {
  const classes = useStyles();
  return (
      <AppBar {...props} style={{ background: '#2980b9' }} userMenu={<MyUserMenu />}>
          <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
          />
          <span className={classes.spacer} />
      </AppBar>
  );
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={Menu} />;

const dash = () => (
    <Card>
        <Title title="Administration" />
        <CardContent>
        <Typography
              variant="h6"
              color="inherit"
              id="react-admin-title"
              align="center"
          >
            Sélectionnez une catégorie.
        </Typography>
        </CardContent>
    </Card>
);

export default () => <HydraAdmin
  dashboard={ dash }
  entrypoint={ process.env.REACT_APP_API_ENTRYPOINT }
  dataProvider={ dataProvider }
  authProvider={ authProvider }
  i18nProvider={ i18nProvider }
  loginPage={ MyLoginPage }
  layout={ MyLayout }
>
{
    [
        {name: 'roles', label: 'Rôles', list: RoleList, roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']},
        {name: 'moods', list: MoodList, show: MoodShow, create: MoodCreate, edit: MoodEdit, label: 'Humeurs', roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']},
        {name: 'groups', list: GroupList, show: GroupShow, create: GroupCreate, edit: GroupEdit, label: 'Groupes', roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']},
        {name: 'facilities', list: FacilityList, show: FacilityShow, create: FacilityCreate, edit: FacilityEdit, label: 'Établissements', roles: ['ROLE_SUPER_ADMIN','ROLE_ADMIN']},
        {name: 'people', list: PeopleList, show: PeopleShow, create: PeopleCreate, edit: PeopleEdit, label: 'Utilisateurs', roles: ['ROLE_SUPER_ADMIN','ROLE_ADMIN']},
        {name: 'posts', list: PostList, show: PostShow, edit: PostEdit, label: 'Publications', roles: ['ROLE_SUPER_ADMIN','ROLE_ADMIN']}
    ].filter(({ roles }) => {
      const role = localStorage.getItem('permissions');
      return roles.includes(role);
    })
    .map( ({name, list, show, create, edit, label}, index) => (
        <ResourceGuesser
          key={ index }
          options={{ label: label }}
          name={ name }
          list={ list }
          show={ show }
          create={ create }
          edit={ edit }
        />
    ))
}
</HydraAdmin>;
