import React from 'react';
import { Show, FunctionField, SimpleShowLayout, TextField, ReferenceField, ReferenceManyField, DateField, Pagination, SingleFieldList, ChipField, BooleanField, ImageField } from 'react-admin';

const PostShowTitle = ({ record }) => {
  return <span>Détail d'une Publication</span>;
};

export default (props) => (
    <Show {...props} title="Détail d'une Publication" title={<PostShowTitle />}>
        <SimpleShowLayout>
            <TextField source="content" label="Contenu" />
            <BooleanField source="archived" label="Verrouillé" />
            <DateField source="publishedAt" label="Publié le :" />
            <ReferenceField label="Humeur" source="mood" reference="moods">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceField label="Auteur" source="person" reference="people">
                <TextField source="username" />
            </ReferenceField>
            <ReferenceManyField
              pagination={<Pagination />}
              label="Etablissements"
              reference="facilities"
              target="posts"
              sort={{ field: 'label', order: 'ASC' }}
            >
                <SingleFieldList>
                    <ChipField source="label" />
                </SingleFieldList>
            </ReferenceManyField>
            <FunctionField label="Signalements" render={record => `${record.reports.length}`}/>
            <FunctionField label="Appréciations" render={record => `${record.likes.length}`}/>
            <ImageField source="mediaUrl" title="Image" label="Image" />
        </SimpleShowLayout>
    </Show>
);
