import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';

const FacilityEditTitle = ({ record }) => {
  return <span>Modification d'un Etablissement : {record ? `${record.label}` : ''}</span>;
};

export default (props) => (
    <Edit {...props} title={<FacilityEditTitle />} undoable={false}>
        <SimpleForm>
            <TextInput label="Titre" source="label" />
            {
              localStorage.getItem('permissions') === 'ROLE_SUPER_ADMIN' &&
              <ReferenceInput label="Groupe" source="group" reference="groups" validate={[required()]}>
                <SelectInput optionText="label" />
              </ReferenceInput>
            }
        </SimpleForm>
    </Edit>
);
