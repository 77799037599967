import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton } from 'react-admin';

export default (props) => (
    <List
      {...props}
      sort={{ field: 'label', order: 'ASC' }}
      hasCreate={false}
      exporter ={false}
      title="Liste des Humeurs"
      bulkActionButtons={false}
    >
        <Datagrid>
          <TextField source="label" label="Titre" />
          <ShowButton />
        </Datagrid>
    </List>
);
