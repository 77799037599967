import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const PostEditTitle = ({ record }) => {
  return <span>Modification d'une Publication</span>;
};

export default (props) => (
    <Edit {...props} title={<PostEditTitle />} undoable={false}>
        <SimpleForm>
            <BooleanInput label="Verrouillé" source="archived" />
        </SimpleForm>
    </Edit>
);
