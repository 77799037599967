import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, ReferenceManyField, SingleFieldList, ChipField, Pagination } from 'react-admin';

const FacilityShowTitle = ({ record }) => {
  return <span>Détail d'un Etablissement : {record ? `${record.label}` : ''}</span>;
};

export default (props) => (
    <Show {...props} title={<FacilityShowTitle />}>
        <SimpleShowLayout>
            <TextField source="label" label="Titre" />
            <ReferenceField label="Groupe" source="group" reference="groups">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceManyField
              pagination={<Pagination />}
              label="Utilisateurs"
              reference="people"
              target="facilities"
              sort={{ field: 'username', order: 'DESC' }}
            >
                <SingleFieldList>
                    <ChipField source="username" />
                </SingleFieldList>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);
