import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, ReferenceManyField, DateField, Pagination, SingleFieldList, ChipField, BooleanField } from 'react-admin';

const PeopleShowTitle = ({ record }) => {
  return <span>Détail d'un Utilisateur : {record ? `${record.lastName} ${record.firstName}` : ''}</span>;
};

export default (props) => (
    <Show {...props} title="Détail d'un Utilisateur" title={<PeopleShowTitle />}>
        <SimpleShowLayout>
            <TextField source="lastName" label="Nom" />
            <TextField source="firstName" label="Prénom" />
            <TextField source="username" label="Pseudo" />
            <TextField source="lastLogged" label="Date de dernière connexion" />
            <BooleanField source="archived" label="Verrouillé" />
            <ReferenceField label="Rôle" source="role" reference="roles">
                <TextField source="displayedLabel" />
            </ReferenceField>
            <ReferenceField label="Groupe" source="group" reference="groups">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceManyField
              pagination={<Pagination />}
              label="Etablissements"
              reference="facilities"
              target="persons"
              sort={{ field: 'label', order: 'ASC' }}
            >
                <SingleFieldList>
                    <ChipField source="label" />
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceField label="Humeur" source="mood" reference="moods">
                <TextField source="label" />
            </ReferenceField>
            <DateField source="moodSince" label="Humeur depuis le :" />
        </SimpleShowLayout>
    </Show>
);
