import React from 'react';
import { Create, SimpleForm, TextInput, Edit } from 'react-admin';

const MoodEditTitle = ({ record }) => {
  return <span>Modification d'une Humeur : {record ? `${record.label}` : ''}</span>;
};

export default (props) => (
    <Edit {...props} title={<MoodEditTitle />} undoable={false}>
        <SimpleForm>
            <TextInput label="Titre" source="label" />
            <TextInput label="Icône" source="icon" />
        </SimpleForm>
    </Edit>
);
