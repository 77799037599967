import React from 'react';
import {
  List,
  Datagrid,
  TextField
} from 'react-admin';

export default (props) => (
    <List {...props} hasCreate={false} exporter={false} title="Liste des Rôles" bulkActionButtons={false}>
      <Datagrid>
          <TextField source="displayedLabel" label="Titre" sortable={false}/>
      </Datagrid>
    </List>
);
