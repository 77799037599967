import React from 'react';
import { Edit, SimpleForm, TextInput, PasswordInput, ReferenceInput, SelectInput, BooleanInput, required, AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';

const PeopleEditTitle = ({ record }) => {
  return <span>Modification d'un Utilisateur : {record ? `${record.lastName} ${record.firstName}` : ''}</span>;
};

export default (props) => (
    <Edit {...props} title={<PeopleEditTitle />} undoable={false}>
        <SimpleForm>
            <TextInput label="Nom" source="lastName" />
            <TextInput label="Prénom" source="firstName" />
            <TextInput label="Nom d'utilisateur" source="username" />
            <PasswordInput label="Nouveau Mot de Passe" source="plainPassword" />
            <PasswordInput label="Cofirmation de Mot de Passe" source="plainPasswordConfirm" />
            <BooleanInput label="Verrouillé" source="archived"/>
            <ReferenceInput label="Rôle" source="role" reference="roles" validate={[required()]}>
                <SelectInput optionText="displayedLabel" />
            </ReferenceInput>
            <ReferenceInput label="Groupe" source="group" reference="groups" validate={[required()]}>
                <SelectInput optionText="label" />
            </ReferenceInput>
            <ReferenceArrayInput label="Etablissements" source="facilities" reference="facilities" filterToQuery={searchText => ({ label: searchText })}>
              <AutocompleteArrayInput optionText="label" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
