import React from 'react';
import { List, Datagrid, TextField, ShowButton, EditButton, ReferenceField, Filter, TextInput, SelectInput, ReferenceInput } from 'react-admin';

const PeopleFilter = (props) => (
  <Filter {...props}>
      <ReferenceInput label="Humeur" source="mood" reference="moods">
        <SelectInput optionText="label" />
      </ReferenceInput>
  </Filter>
);

export default (props) => (
    <List {...props} filters={<PeopleFilter />} sort={{ field: 'lastName', order: 'ASC' }} title="Liste des Utilisateurs" exporter={false} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="lastName" label="Nom"/>
          <TextField source="firstName" label="Prénom"/>
          <TextField source="username" label="Pseudo"/>
          <ReferenceField label="Humeur" source="mood" reference="moods" sortable={false}>
              <TextField source="label" />
          </ReferenceField>
          <ShowButton />
          <EditButton />
        </Datagrid>
    </List>
);
