import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ShowButton,
  EditButton
} from 'react-admin';

export default (props) => (
    <List {...props} sort={{ field: 'label', order: 'ASC' }} title="Liste des Etablissements" exporter={false} bulkActionButtons={false}>
      <Datagrid>
          <TextField source="label" label="Titre" />
          <ReferenceField label="Groupe" source="group" reference="groups" sortable={false}>
              <TextField source="label" />
          </ReferenceField>
          <ShowButton />
          <EditButton />
      </Datagrid>
    </List>
);
